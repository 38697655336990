import React from 'react';

const Footer =  ({ siteTitle }) => {

  return (
    <footer className="footer">
      <div className="footer-belt">
        <div className="company-sign">
        &copy; 2020 {siteTitle}
      </div>
        <div className="social-media">
          <a href="https://www.facebook.com/profile.php?id=100077565440660"><div className="social-facebook">Facebook</div></a>
          <a href="https://twitter.com/slotonlineindo"><div className="social-twitter">Twitter</div></a>
          <a href="https://id.pinterest.com/slotonlineidn/_saved/"><div className="social-pinterest">Instagram</div></a>
        </div>
      </div>
    </footer>
  )
};

export default Footer;
